<!-- =========================================================================================
  File Name: AddNewDataSidebar.vue
  Description: Add New Data - Sidebar component
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary"
              class="add-new-data-sidebar items-no-padding" spacer
              v-model="isSidebarActiveLocal">
    <div class="mt-6 flex items-center justify-between px-6">
      <h3>{{ Object.entries(this.data).length === 0 ? "Add New" : "Edit" }} STREAM</h3>
      <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <VuePerfectScrollbar class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">

      <div class="p-6">

        <!-- NAME -->
        <vs-input label="Name" v-model="name" class="w-full" name="item-name" v-validate="'required'"/>
        <span class="text-danger text-sm" v-show="errors.has('name')">{{ errors.first('name') }}</span>

        <!-- Description -->

        <br/>
        <label for="description" class="vs-input--label">Description</label>
        <vs-textarea v-model="description"/>

        <!-- Stream Order -->
        <label class="vs-input--label">Orders</label>
        <v-select :options="OrderOptions" :clearable="false" :dir="$vs.rtl ? 'rtl' : 'ltr'" v-model="orders"
                  class="mb-4 md:mb-0" data-vv-as="Orders"/>
        <!-- Stream Image -->
        <template v-if="dataImg">
          <div class="img-container w-64 mx-auto flex items-center justify-center">
            <img :src="dataImg" alt="img" class="responsive">
          </div>
          <!-- Image upload Buttons -->
          <div class="modify-img flex justify-between my-5">
            <input type="file" class="hidden" ref="updateImgInput" @change="updateCurrImg" accept="image/*">
            <vs-button class="mr-4" @click="$refs.updateImgInput.click()">Update Image</vs-button>
            <vs-button class="vs-button-secondary" @click="dataImg = null">Remove Image</vs-button>
          </div>
        </template>

        <div class="upload-img mt-5" v-if="!dataImg">
          <input type="file" class="hidden" ref="uploadImgInput" @change="updateCurrImg" accept="image/*">
          <vs-button @click="$refs.uploadImgInput.click()">Upload Image</vs-button>
        </div>

        <vs-alert v-if="customError != ''"
                  icon-pack="feather"
                  close-icon="icon-x"
                  color="danger"
        >{{ customError }}
        </vs-alert>
      </div>
    </VuePerfectScrollbar>

    <div class="flex flex-wrap items-center justify-between p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid">Submit</vs-button>
      <vs-button class="vs-button-secondary" @click="isSidebarActiveLocal = false">Cancel</vs-button>
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import {mapActions} from "vuex";
import vSelect from 'vue-select'

export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      default: () => {
      },
    },
    totalCount: {
      type: Number
    }
  },
  watch: {
    isSidebarActive(val) {
      if (!val) return

      this.customError = "";
      if (Object.entries(this.data).length === 0) {
        this.initValues()
        this.$validator.reset()
      } else {
        this.dataId = this.data._id
        this.code = this.data.code
        this.name = this.data.name
        this.orders = this.data.orders
        this.dataImg = this.data.icon
        this.description = this.data.description
      }
    }
  },
  data() {
    return {
      customError: "",
      dataImg: null,
      dataId: null,
      name: "",
      description: "",
      image: null,
      orders: {label: 'Select Order', value: ''},
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: .60,
      },
      OrderOptions: []
    }
  },
  computed: {
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive
      },
      set(val) {
        if (!val) {
          this.$emit('closeSidebar')

        }
      }
    },
    isFormValid() {
      return !this.errors.any() && this.name && this.description
    }
  },
  methods: {
    ...mapActions("stream", [
      "updateStream",
      "addStream",
    ]),
    showMessage(title, message, color) {
      this.$vs.notify({
        title: title,
        text: message,
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: color
      })
    },
    success() {
      this.$vs.notify({
        title: 'Success',
        text: 'Data Saved',
        color: 'success',
        iconPack: 'feather',
        position: 'top-right',
        icon: 'icon-check-circle'
      })
    },
    error(err) {
      this.$vs.notify({
        title: 'Error',
        text: err.message,
        color: 'error',
        iconPack: 'feather',
        position: 'top-right',
        icon: 'icon-times'
      })
    },
    initValues() {
      if (this.data.id) return
      this.dataId = null
      this.name = ""
      this.description = null
      this.dataImg = null
      this.image = null

    },
    async submitData() {
      await this.$validator.validateAll().then(result => {
        if (result) {
          let data = new FormData();

          data.append('name', this.name);
          data.append('description', this.description);
          data.append('orders', this.orders.value);
          data.append('image', this.image);
          data.append('streamId', this.dataId);

          if (this.dataId !== null) {

            if (this.dataImg == null) {
              this.customError = "Image field is required.";
              return;
            }

            this.$vs.loading()
            this.updateStream(data)
              .then((res) => {
                this.$vs.loading.close()
                this.showMessage("Success", "Stream updated successfully.", "success");
                this.initValues()
                this.$emit('closeSidebar')
              })
              .catch(err => {
                this.$vs.loading.close()
                console.error(err)
              })
          } else {

            if (this.dataImg == null) {
              this.customError = "Image field is required.";
              return;
            }

            this.$vs.loading()
            this.addStream(data)
              .then((res) => {
                this.$vs.loading.close()
                this.showMessage("Success", "Stream added successfully.", "success");
                this.initValues()
                this.$emit('closeSidebar')
              })
              .catch(err => {
                this.$vs.loading.close()
                this.customError = err.response.data.message;
              })
          }
        }
      })
    },
    updateCurrImg(input) {
      if (input.target.files && input.target.files[0]) {
        this.image = input.target.files[0];
        var reader = new FileReader()
        reader.onload = e => {
          this.dataImg = e.target.result
        }
        reader.readAsDataURL(input.target.files[0])
      }
    }

  },
  components: {
    VuePerfectScrollbar,
    vSelect
  },
  mounted() {
    this.customError = "";
    if (Object.entries(this.data).length === 0) {
      this.initValues()
      this.$validator.reset()
    } else {
      this.dataId = this.data._id
      this.code = this.data.code
      this.name = this.data.name
      if (this.data.orders) {
        this.orders = {label: this.data.orders, value: this.data.orders}
      }
      this.dataImg = this.data.icon
      this.description = this.data.description
    }
  },
  created() {
    for (let i = 1; i <= this.totalCount; i++) {
      this.OrderOptions.push({label: i, value: i});
    }
  }
}
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);
}
</style>
