<template>
  <div>
    <stream-sidebar v-if="addNewDataSidebar" :isSidebarActive="addNewDataSidebar" @closeSidebar="toggleDataSidebar" :data="sidebarData" :totalCount="streams.length"/>
    <vx-card>
      <div class="op-block mt-5 p-0 shadow-none">
        <vs-row>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="2" vs-sm="3" vs-xs="6">
            <h3 class="font-bold h4">Streams</h3>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-lg="8" vs-sm="6" vs-xs="6">
            <h3 class="font-bold h4">Description</h3>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="left" vs-lg="1" vs-sm="1" vs-xs="12">
            <h3 class="font-bold h4">Orders</h3>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="left" vs-lg="1" vs-sm="2" vs-xs="12">
            <vs-button class="mr-4 custom-vs-btn" @click="addNewData">Add</vs-button>
          </vs-col>
          <vs-divider/>
        </vs-row>

        <vs-row :key="index" v-for="(item,index) in streams">
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="2" vs-sm="3" vs-xs="12"
                  style="flex-wrap: wrap;">
            <div class="large mb-1" style="width: 100px; height: 100px;">
              <img v-if="item.icon != ''" :src="item.icon"
                   class="inline-block" style="height:75px; object-fit: contain;">
              <img v-else src="../../../../../../assets/images/personhood/no-img.png" width="100%" height="100%"
                   class="inline-block" style="object-fit: scale-down">
            </div>
            <h5 class="font-bold mb-2" style="display: flex; flex: 0 0 100%; justify-content: center;">
              {{ item.name }}</h5>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-lg="8" vs-sm="6" vs-xs="12">
            <p class="h5 mb-2 text-justify">{{ item.description }}</p>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="left" vs-lg="1" vs-sm="1" vs-xs="12">
            {{item.orders}}
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="left" vs-lg="1" vs-sm="2" vs-xs="12">
            <a href="#" @click.stop="editData(item)">
              <feather-icon icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current"
              />
            </a>
            <a href="#" @click.stop="deleteData(item._id)">
              <feather-icon icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="ml-2"
              />
            </a>

          </vs-col>
          <vs-divider/>
        </vs-row>
      </div>
    </vx-card>

  </div>
</template>
<script>
import StreamSidebar from "./StreamSidebar";
import Swal from 'sweetalert2/dist/sweetalert2';
import 'sweetalert2/src/sweetalert2.scss'
import {mapActions} from "vuex";

export default {
  components: {StreamSidebar},
  data() {
    return {
      path: '',
      selected: [],
      streams: [],
      itemsPerPage: 4,
      isMounted: false,

      // Data Sidebar
      addNewDataSidebar: false,
      sidebarData: {},
    }
  },
  computed: {
    currentPage() {
      if (this.isMounted) {
        return this.$refs.table.currentx
      }
      return 0
    },

    queriedItems() {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.streams.length
    }
  },
  methods: {
    ...mapActions("stream", [
      "fetchStreams",
      "deleteStream",
    ]),
    addNewData() {
      this.sidebarData = {}
      this.toggleDataSidebar(true)
    },
    showMessage(title, message, color) {
      this.$vs.notify({
        title: title,
        text: message,
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: color
      })
    },
    deleteData(id) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.value) {
          this.delete(id)
        }
      })

    },
    delete(id) {

      this.deleteStream(id)
        .then((res) => {
          this.showMessage("Success", "Stream deleted successfully.", "success");
          this.coreSkills = this.$store.state.stream.coreSkills;
        })
        .catch(err => {
          console.error(err)
        })
    },
    editData(data) {
      this.sidebarData = data
      this.toggleDataSidebar(true)
    },
    toggleDataSidebar(val = false) {
      this.addNewDataSidebar = val
    },
  },
  created() {
    this.$vs.loading()
    this.fetchStreams()
      .then((res) => {
        this.$vs.loading.close()
        this.streams = this.$store.state.stream.streams;
      })
      .catch(err => {
        console.error(err)
      });
  },
}
</script>
<style scoped lang="scss">
.justify-text {
  @media (max-width: 600px) {
    text-align: justify;
  }
}
</style>
